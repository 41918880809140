import {numberAddDigits, numberNoZero} from "./number";

const kabbalah_let_num: { value: number, letters: string[] }[] = [
    {value: 1, letters: ['A']},
    {value: 2, letters: ['B']},
    {value: 3, letters: ['G']},
    {value: 4, letters: ['D']},
    {value: 5, letters: ['E']},
    {value: 6, letters: ['V', 'U', 'W']},
    {value: 7, letters: ['Z']},
    {value: 8, letters: ['H']},
    {value: 10, letters: ['I', 'J', 'Y']},
    {value: 11, letters: ['C', 'K']},
    {value: 12, letters: ['L']},
    {value: 13, letters: ['M']},
    {value: 14, letters: ['N']},
    {value: 15, letters: ['X']},
    {value: 16, letters: ['O']},
    {value: 17, letters: ['F', 'P']},
    {value: 19, letters: ['Q']},
    {value: 20, letters: ['R']},
    {value: 21, letters: ['S']},
    {value: 22, letters: ['T']}
];
export const kabbalah_name_score = (name: string) => {
    const safe_name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase().split('');
    let num = 0;
    safe_name.forEach((c) => {
        let matched = kabbalah_let_num.filter((let_num) => let_num.letters.indexOf(c) > -1);
        if(matched.length === 0) {
            return 0;
        }
        num += matched[0].value;
    })
    // remove zeros
    const num_no_zero = numberNoZero(num);
    // if num less than 23, return number else add the digits
    let res = num_no_zero < 23 ? num_no_zero : numberAddDigits(num_no_zero);
    return res;
}

export const kabbalah_letter_score = (letter: string) => {
    const safe_name = letter.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase().split('');
    let num = 0;
    safe_name.forEach((c) => {
        let matched = kabbalah_let_num.filter((let_num) => let_num.letters.indexOf(c) > -1);
        if(matched.length === 0) {
            return 0;
        }
        num += matched[0].value;
    })
    return num;
}

