// @flow
import * as React from 'react';

import styles from './css/Forms.module.css';
import {kabbalah_letter_score, kabbalah_name_score} from "../lib/kaballah";
import {useState} from "react";
import {numerology_dob_score, numerology_name_score, numerology_reduce,} from "../lib/numerology";
import {numberAddDigits, numberNoZero} from "../lib/number";

type Props = {};

export function AstrologyForm(props: Props) {

    const [healthNumbers, setHealthNumbers] = useState<string>("");
    const [moneyNumber, setMoneyNumber] = useState<string>("");
    const [loveAndRelationshipsNumber, setLoveAndRelationshipsNumber] = useState<string>("");
    const [characterNumber, setCharacterNumber] = useState<string>("");
    const [planetaryInfluenceNumber, setPlanetaryInfluence] = useState<string>("");

    const [idealNumber, setIdealNumber] = useState<string>("");
    const [impressionNumber, setImpressionNumber] = useState<string>("");
    const [expressionNumber, setExpressionNumber] = useState<string>("");
    const [outerExpressionNumber, setOuterExpressionNumber] = useState<string>("");
    const [soulUrgeNumber, setSoulUrgeNumber] = useState<string>("");
    const [dobNumber, setDobNumber] = useState<string>("");

    const scrollTop = ()=>{
        const element = document.getElementsByTagName("form")[0];
        if(element === null) return false;
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    };

    return (
        <>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    const form = event.target as HTMLFormElement;
                    const data = new FormData(form);
                    const name = data.get('name');
                    const dob = data.get('dob');
                    if (dob === null || name === null) return false;
                    const dob_date = new Date(dob.toString());

                    // KABBALAH

                    // for each name
                    const name_parts = name.toString().split(/(\s+)/).filter((x) => x.trim().length > 0);
                    if (name_parts.length < 2) return false;
                    const forename = name_parts[0];
                    const surname = name_parts[name_parts.length - 1];
                    const middlenames = name_parts.length < 2 ? [] : name_parts.slice(1, name_parts.length - 1);

                    // money number
                    let score = name_parts.reduce((a, v) => {
                        return kabbalah_name_score(v) + a;
                    }, 0);
                    const scores = [];
                    score = numberNoZero(score);
                    // if num less than 23, return number else add the digits
                    while(score >= 23) {
                        score = numberAddDigits(score);
                    }
                    setMoneyNumber(score.toString());

                    // health number
                    const firstnameScore = kabbalah_name_score(forename);
                    scores.push(score);
                    scores.push(kabbalah_letter_score(forename.charAt(0)));
                    scores.push(firstnameScore);
                    setHealthNumbers(scores.join(", "));

                    // love and relationships
                    score = firstnameScore + middlenames.reduce((a, v) => { return kabbalah_name_score(v) + a}, 0);
                    if(score >= 23){
                        score = numberAddDigits(numberNoZero(score));
                    }
                    setLoveAndRelationshipsNumber(score.toString());

                    // character number
                    setCharacterNumber(firstnameScore.toString());

                    // planetary / astrological influence number
                    const surnameScore = kabbalah_name_score(surname);

                    score = surnameScore + firstnameScore;
                    if(score >= 23){
                        score = numberAddDigits(numberNoZero(score));
                    }
                    setPlanetaryInfluence(score.toString());

                    // NUMEROLOGY
                    const {vowels : ideal, consonants : impression} = numerology_name_score(name.toString());
                    const expression = numerology_reduce(ideal + impression);
                    const {vowels, consonants : outerExpression} = numerology_name_score(forename.toString(), false);
                    const {vowels : soul_urge} = numerology_name_score(forename, false);
                    const dobNumber = numerology_dob_score(dob_date);

                    setIdealNumber(ideal.toString());
                    setImpressionNumber(impression.toString());
                    setExpressionNumber(expression.toString());
                    setSoulUrgeNumber(soul_urge.toString());
                    setDobNumber(dobNumber.toString());
                    setOuterExpressionNumber(outerExpression.toString());
                    const element = document.getElementById("results");
                    if(element === null) return false;
                    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }}
            >
                <label htmlFor="name">Enter your full name</label>
                <input id="name" name="name" className={`${styles.big} ${styles.wide}`} type="text"/>
                <label htmlFor="dob">Enter your date of birth</label>
                <input id="dob" name="dob" className={styles.big} type="date"
                       max={new Date().toISOString().split("T")[0]}/>
                <button className={styles.big} type="submit">Calculate</button>
            </form>
            <div className={styles.results} id="results">
                <h2>RESULTS</h2>
                <h3>Kabbalah</h3>
                <div className={styles.result}>Your Planetary / Astrological Influence Number is <span className={styles.result}>{planetaryInfluenceNumber}</span></div>
                <div className={styles.result}>Your Character Number is <span className={styles.result}>{characterNumber}</span></div>
                <div className={styles.result}>Your Love and Relationships Number is <span className={styles.result}>{loveAndRelationshipsNumber}</span></div>
                <div className={styles.result}>Your Money Number is <span className={styles.result}>{moneyNumber}</span></div>
                <div className={styles.result}>Your Health Numbers are <span className={styles.result}>{healthNumbers}</span></div>
                <h3>Numerology</h3>
                <div className={styles.result}>Your Ideal Numbers are <span className={styles.result}>{idealNumber}</span></div>
                <div className={styles.result}>Your Impression Number is <span className={styles.result}>{impressionNumber}</span></div>
                <div className={styles.result}>Your Expression Number is <span className={styles.result}>{expressionNumber}</span></div>
                <div className={styles.result}>Your Soul Urge Number is <span className={styles.result}>{soulUrgeNumber}</span></div>
                <div className={styles.result}>Your Outer Expression Number is <span className={styles.result}>{outerExpressionNumber}</span></div>
                <div className={styles.result}>Your Life Path Number is <span className={styles.result}>{dobNumber}</span></div>
                <button className={styles.big} type="button"
                    onClick={scrollTop}
                >Again</button>
            </div>
        </>
    );
};
