import React from 'react';
import './App.css';
import {AstrologyForm} from "./components/AstrologyForm";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>ASTROLOGY</h1>

            </header>
            <AstrologyForm/>
        </div>
    );
}

export default App;
