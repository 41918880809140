import {numberAddDigits, numberNoZero} from "./number";

const numerology_let_num: { value: number, letters: string[] }[] = [];
for (let i = 0; i < 26; i++) {
    let level = Math.floor(i % 9);
    if (!numerology_let_num[level]) {
        numerology_let_num[level] = {value: level + 1, letters: []};
    }
    let c = String.fromCharCode("A".charCodeAt(0) + i);
    numerology_let_num[level].letters.push(c);
}
export const numerology_name_score = (name: string, no_zero : boolean = true) : {vowels: number, consonants: number} => {
    const safe_name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLocaleUpperCase().split('');
    let num_vowels = 0;
    let num_consonants = 0;
    safe_name.forEach((c)=>{
        let matched = numerology_let_num.filter((let_num) => let_num.letters.indexOf(c) > -1);
        if(matched.length === 0) {
            return 0;
        }
        let score = matched[0].value;
        if(["A", "E", "I", "O", "U"].indexOf(c)> -1) {
            num_vowels += score;
        } else {
            num_consonants += score;
        }
    });
    let vowels = numerology_reduce(num_vowels);
    let consonants = numerology_reduce(num_consonants);
    if(!no_zero){
        return {vowels, consonants};
    } else {
        return {vowels: numberNoZero(vowels), consonants: numberNoZero(consonants)};
    }


}

export const numerology_reduce = (score: number): number => {
    if(score < 12 || score === 22) return score;
    let score_no_zero = numberNoZero(score);
    if(score_no_zero === 0) return 0;
    return numerology_reduce(numberAddDigits(score_no_zero));
}

export const numerology_dob_score = (date: Date) => {
    return numberNoZero(numerology_reduce(date.getDate() + (1 + date.getMonth()) + date.getFullYear()));
}
